/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "intranetApi",
            "endpoint": "https://wg8yci8p65.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_TMgVTrnsW",
    "aws_user_pools_web_client_id": "23fqvb9jaec1a809tjpcp54b0g",
    "oauth": {
        "domain": "intranet.auth.us-east-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000,http://localhost:8080,https://master.d20vp54uqa8d24.amplifyapp.com,https://mul-38-lib-regression-testing.d20vp54uqa8d24.amplifyapp.com,https://production.d20vp54uqa8d24.amplifyapp.com,https://skynet.webbank.com,https://test.skynet.webbank.com",
        "redirectSignOut": "http://localhost:3000/login,http://localhost:8080/login,https://master.d20vp54uqa8d24.amplifyapp.com/login,https://mul-38-lib-regression-testing.d20vp54uqa8d24.amplifyapp.com/login,https://production.d20vp54uqa8d24.amplifyapp.com/login,https://skynet.webbank.com/login,https://test.skynet.webbank.com/login",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "devteam-image-repo",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
