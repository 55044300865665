import React, { useState, useEffect } from "react"; // reactive state
import "rsuite/dist/rsuite.min.css";
import { Sidebar, Sidenav, Nav, Loader } from "rsuite";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import store, { getUserCognitoGroups } from "../store";
import api from "../api";
export default function SideMenu(props) {
  const [loading, setLoading] = useState(true);
  const [isProductAdmin, setIsProductAdmin] = useState(false);
  const [isContractAbstractAdmin, setIsContractAbstractAdmin] = useState(false);
  const [isTreasuryAdmin, setIsTreasuryAdmin] = useState(false);
  const isFaqAdmin = store.user.groups.includes("faqAdmin");
  const isLegalAdmin = store.user.groups.includes("legalResponsibilitiesAdmin");
  const isPartnerDetailsAdmin = store.user.groups.includes("partnerReferenceAdmin");

  const checkPermissions = () => {
    const checkPermissionAsync = async () => {
      const checkList = [getUserCognitoGroups("productAdmin"), getUserCognitoGroups("contractAbstractAdmin"), getUserCognitoGroups("treasuryAdmin")];
      const [responseProduct, responseContract, responseTreasuryAdmin] = await Promise.all(checkList);
      setIsProductAdmin(responseProduct);
      setIsContractAbstractAdmin(responseContract);
      setIsTreasuryAdmin(responseTreasuryAdmin);
    };
    checkPermissionAsync().catch((e) => e);
  };

  // sidebar
  const [openKeys, setOpenKeys] = useState(props.defaultOpenMenus);
  const [expanded, setExpanded] = useState(false);
  const expand = () => setExpanded(true);
  const contract = () => setExpanded(false);
  const PartnerPortal = () => <i className="fa-solid fa-handshake side-nav-icon" />;
  const CompliancePortal = () => <i className="fa-solid fa-clipboard-check side-nav-icon" />;
  const TreasuryPortal = () => <i className="fa-solid fa-coins side-nav-icon" />;
  const LegalPortal = () => <i className="fa-solid fa-gavel side-nav-icon" />;
  const PartnerResponsibilitiesIcon = () => <i className="side-nav-icon" />;
  const ProductFactSheetAdminIcon = () => <i className="side-nav-icon" />;
  const LegalResponsibilitiesIcon = () => <i className="side-nav-icon" />;
  const SpdIcon = () => <i className="side-nav-icon" />;
  const ReportsPortal = () => <i className="fa-solid fa-chart-line side-nav-icon" />;
  const ReportsDashboardIcon = () => <i className="side-nav-icon" />;
  const FaqPortal = () => <i className="fa-solid fa-question side-nav-icon" />;
  const Faq = () => <i className="side-nav-icon" />;
  const navigate = useNavigate();
  const [tableauJwt, setTableauJwt] = useState(store.user.tableauJwt);

  useEffect(() => {
    if(!store.user.tableauJwt){
      api.reports.getTableauToken({username: store?.user?.email}).then((res) => {
        setTableauJwt(res?.token);
        store.user.tableauJwt = res?.token;
      });
    }
  }, []);

  const NavItemLink = React.forwardRef((props, ref) => {
    const location = useLocation();
    const goToPath = (path = "/") => {
      navigate(path);
    };

    function handleState() {
      props.name
        ? navigate(props.to, {
            state: { title: props.name, reportUrl: props.reportUrl, tableauJwt: props.tableauJwt },
          })
        : goToPath(props.to);
    }

    return <Nav.Item {...props} ref={ref} active={props.to === location.pathname} componentclass={NavLink} onClick={handleState} />;
  });

  const toggleOpenMenus = (key) => {
    const keyIdx = openKeys.indexOf(key);
    const temp = [...openKeys];
    if (keyIdx > -1) {
      temp.splice(keyIdx, 1);
    } else {
      temp.push(key);
    }
    setOpenKeys([...temp]);
  };

  const [reports, setReports] = useState([]);
  const structureReportsByGroup = async (reports) => {
    let reportsList = [];
    const uniqueDepartments = Array.from(
      new Set(
        reports.flatMap((report) => report.reportDetails.filter((detail) => detail.detailKey === "Department").map((detail) => detail.detailValue))
      )
    );
    uniqueDepartments.forEach((department) => {
      let departmentReports = reports.filter((report) =>
        report.reportDetails
          .filter((detail) => detail.detailKey === "Department")
          .map((detail) => detail.detailValue)
          .includes(department)
      );
      reportsList.push({ id: department, title: department, children: departmentReports });
    });
    setReports(reportsList);
  };

  const goToCompliance = () => {
    let complianceUrl = "https://webbankeo.sharepoint.com/sites/WebBankCompliance";
    window.open(complianceUrl, "_blank");
  };

  useEffect(() => {
    checkPermissions();
    if (loading && !store.getters.setupComplete()) {
      let checkReportsListExist = setInterval(() => {
        if (store.getters.setupComplete()) {
          clearInterval(checkReportsListExist);
          structureReportsByGroup(store.embeddedReports);
          setLoading(false);
        }
      }, 1000);
    } else {
      structureReportsByGroup(store.embeddedReports);
      setLoading(false);
    }
  }, [loading]);

  return (
    <Sidebar collapsible width={expanded ? 300 : 56}>
      <Sidenav expanded={expanded} style={{ height: "calc(100vh - 6rem)", overflowY: "scroll"}} onMouseOver={expand} onMouseOut={contract} openKeys={openKeys}>
        <Sidenav.Body>
          <Nav activeKey="1">
            <Nav.Menu className="pl-0" title="Partner Portal" eventKey="1" icon={<PartnerPortal />} onClick={() => toggleOpenMenus("1")}>
              <NavItemLink className="pl-0" eventKey="1-1" icon={<SpdIcon />} to="/spd">
                <span className="hover-3">Strategic Partners Dashboard</span>
              </NavItemLink>
              <NavItemLink className="pl-0" eventKey="1-2" icon={<PartnerResponsibilitiesIcon />} to="/partnerresponsibilities">
                <span className="hover-3">Partner Responsibilities</span>
              </NavItemLink>
              {isProductAdmin && (
                <NavItemLink className="pl-0" eventKey="1-3" icon={<PartnerResponsibilitiesIcon />} to="/adminModules/products">
                  <span className="hover-3">Products</span>
                </NavItemLink>
              )}
              {isContractAbstractAdmin && (
                <NavItemLink className="pl-0" eventKey="1-4" icon={<PartnerResponsibilitiesIcon />} to="/adminModules/contract-abstract">
                  <span className="hover-3">Contract Abstract Admin</span>
                </NavItemLink>
              )}
              {isPartnerDetailsAdmin && (
                <NavItemLink className="pl-0" eventKey="1-5" icon={<ProductFactSheetAdminIcon />} to="/adminModules/partner-details">
                  <span className="hover-3">Partner Fact Sheet Admin</span>
                </NavItemLink>
              )}
              {isPartnerDetailsAdmin && (
                <NavItemLink className="pl-0" eventKey="1-5" icon={<ProductFactSheetAdminIcon />} to="/adminModules/product-details">
                  <span className="hover-3">Product Fact Sheet Admin</span>
                </NavItemLink>
              )}
            </Nav.Menu>
            <Nav.Menu className="pl-0" title="Reports Portal" eventKey="3" icon={<ReportsPortal />} onClick={() => toggleOpenMenus("3")}>
              <NavItemLink className="pl-0" eventKey="3-1" icon={<ReportsDashboardIcon />} to="/reports">
                <span className="hover-3">Reports Dashboard</span>
              </NavItemLink>
              {loading ? (
                <Loader className="pl-6" size="sm" content="Loading..." />
              ) : (
                reports?.map((r) => {
                  return (
                    <div key={r.id} className="pl-3 help">
                      <label
                        className="pl-6 is-clickable"
                        onClick={() => {
                          sessionStorage.setItem("reportFilter", r.title);
                          navigate("/reports");
                        }}
                      >
                        {r.title}
                      </label>
                      {r.children.map((c) => {
                        return (
                          <NavItemLink
                            key={c.id}
                            className="pl-5 py-0"
                            eventKey={"3-2"}
                            to={"/reports/report-view"}
                            name={c.title}
                            tableauJwt={tableauJwt}
                            reportUrl={c.reportUrl}
                          >
                            <span className="ml-5 mr-2">-</span>
                            <span className="hover-3">{c.title}</span>
                          </NavItemLink>
                        );
                      })}
                    </div>
                  );
                })
              )}
            </Nav.Menu>
            <Nav.Menu className="pl-0" title="Faq Portal" eventKey="2" icon={<FaqPortal />} onClick={() => toggleOpenMenus("2")}>
              <NavItemLink className="pl-0" eventKey="2-1" icon={<Faq />} to="/faq">
                <span className="hover-3">FAQ</span>
              </NavItemLink>
              {isFaqAdmin && (
                <div>
                  <NavItemLink className="pl-0" eventKey="2-2" icon={<Faq />} to="/faq-admin-question">
                    <span className="hover-3">Questions Admin</span>
                  </NavItemLink>
                  <NavItemLink className="pl-0" eventKey="2-3" icon={<Faq />} to="/faq-admin-section">
                    <span className="hover-3">Sections Admin</span>
                  </NavItemLink>
                </div>
              )}
            </Nav.Menu>
            <Nav.Menu className="pl-0" title="Legal Portal" eventKey="4" icon={<LegalPortal />} onClick={() => toggleOpenMenus("4")}>
              {isLegalAdmin && (
                <NavItemLink className="pl-0" eventKey="4-1" icon={<SpdIcon />} to="/legaldashboard">
                  <span className="hover-3">Legal Topic Dashboard</span>
                </NavItemLink>
              )}
              <NavItemLink className="pl-0" eventKey="4-2" icon={<LegalResponsibilitiesIcon />} to="/legalresponsibilities">
                <span className="hover-3">Legal Responsibilities</span>
              </NavItemLink>
            </Nav.Menu>
            <Nav.Menu className="pl-0" title="Compliance" eventKey="5" icon={<CompliancePortal />} onClick={goToCompliance} noCaret>
              <span className="nav-icon">
                <CompliancePortal />
              </span>
              Compliance
            </Nav.Menu>
            {isTreasuryAdmin && (
              <Nav.Menu className="pl-0" title="Treasury Portal" eventKey="6" icon={<TreasuryPortal />} onClick={() => toggleOpenMenus("6")}>
                <NavItemLink className="pl-0" eventKey="6-1" icon={<SpdIcon />} to="/treasury">
                  <span className="hover-3">Treasury Functions</span>
                </NavItemLink>
              </Nav.Menu>
            )}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </Sidebar>
  );
}
